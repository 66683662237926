import React, {constructor, useState, useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Loadable from "@loadable/component";

const RssFeed = Loadable(() => import('../components/feed_test'))


export default () => {

    return (
        <RssFeed />
    )
}